<template>
  <div>
    <div class="row">
      <div
        v-for="(message, index) in messages"
        :key="index++"
        class="col-12 py-3"
      >
        <div class="card shadow border-0 p-0">
          <div class="card-body">
            <br />
            <div class="row my-1">
              <div class="col-2">Nom complet :</div>
              <div class="col fw-bold">
                {{ message.fullName }}
              </div>
            </div>

            <div class="row d-flex my-1">
              <div class="col-2">Nº de Téléphone :</div>
              <div class="col fw-bold">
                {{ message.phone }}
              </div>
            </div>

            <div class="row d-flex my-1">
              <div class="col-2">E-mail :</div>
              <div class="col fw-bold">
                {{ message.email }}
              </div>
            </div>

            <div class="row d-flex my-1">
              <div class="col-2">Message :</div>
              <div class="col fw-bold">
                {{ message.text }}
              </div>
            </div>

            <div class="d-flex justify-content-between">
              <button class="btn btn-outline-danger">Supprimer</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  beforeCreate() {
    this.$store.dispatch("message/fetch");
  },
  computed: {
    ...mapGetters("message", { messages: "getMessages" }),
  },
};
</script>
